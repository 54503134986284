import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { refreshStages } from '../../store/auth/actions';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import env from '../../environment';
import { useSelector, useDispatch } from 'react-redux';
import {
	ecoBudget,
	maxbudgetGreen,
	mortgageGreen,
	subrogationGreen
} from '../../store/data/actions';
import { isMobile } from 'react-device-detect';
import { Modal, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

const { Text } = Typography;

const CreateProduct = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
	const [chargeRedirect,] = useState(false);
	const userToken = useSelector((state) => state.auth.token);
	const changeStages = useSelector((state) => state.auth.refreshStages);
	const [loading, setLoading] = useState(false);
	const [showModalCreateMortgage, setShowModalCreateMortgage] = useState(false);
	const userOperations = useSelector((state) => state.auth.operations);
	const navigate = useNavigate();
	let userOperationsMortgage = undefined;

	if (userOperationsMortgage === undefined) {
		userOperationsMortgage = userOperations?.filter((operation) => operation.type === 'mortgage');
	}

	const analytics = getAnalytics();

	const createMortgage = () => {
		setLoading(true);
		if (props.type === 'mortgage') {
			logEvent(analytics, 'select_content', {
				content_type: 'new.product',
				content_id: 'new.product.mortgage'
			});
		} else if (props.type === 'green-mortgage') {
			logEvent(analytics, 'select_content', {
				content_type: 'new.product',
				content_id: 'new.product.greenMortgage'
			});
		} else if (props.type === 'subrogation') {
			logEvent(analytics, 'select_content', {
				content_type: 'new.product',
				content_id: 'new.product.subrogation'
			});
		} else {
			logEvent(analytics, 'select_content', {
				content_type: 'new.product',
				content_id: 'new.product.greenSubgrogation'
			});
		}

		axios
			.get(
				`${env.api.url}/api/clients/createCustomerMortgage?type=${props.type}&houseState=${props.houseState}`,
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					dispatch(refreshStages(!changeStages));
					dispatch(ecoBudget(0));
					// updateData("mortgage", "ecoBudget",  );
					dispatch(mortgageGreen(false));
					dispatch(subrogationGreen(false));
					dispatch(maxbudgetGreen(0));
					setLoading(false);

					const id = response.data.data.id;
					axios
						.post(
							`${env.api.url}/campaigns/runner/experiment/by-event`,
							{
								event: 'questions-mortgages',
								data: {
									operationId: id
								}
							},
							{
								headers: { Authorization: `Bearer ${userToken}` }
							}
						)
						.then((response) => {
							//setOption(response.data.data);

							if (response.data.data.mortgageQuestions === '2022') {
								navigate(`/testA/operations/${id}/basic`);
								sessionStorage.setItem('modalSub', false);
							} else {
								navigate(`/operations/${id}/basic`);
							}
						});

					/* setTimeout(() => {
            setChargeRedirect(true);
            setLoading(false);
          }, 1000);*/
				}
			});
	};

	

	const handleCancelModal = (id) => {
		setShowModalCreateMortgage(false);
	
		if (id === 'home') {
			navigate('/home');
		}
	};

	const handleOk = () => {
		setShowModalCreateMortgage(false);
		 createMortgage();
	};

	

	if (loading) {
		return <Spin indicator={antIcon} className="spin" />;
	}

	const style = props.style || {
		marginBottom: isMobile ? '24px' : '40px',
		marginTop: '48px',
		textAlign: 'center'
	};

	return (
        (<div className="gibobs" style={style}>
            <Button
				className="button-primari-gibobsNew"
				type="primary"
				onClick={() => {
					userOperationsMortgage.length > 0 ? setShowModalCreateMortgage(true) : handleOk();
				}}
				style={{
					height: props.style && props.style.height && props.style.height,
					fontWeight: props.style && props.style.fontWeight && props.style.fontWeight
				}}>
				{/*t("createMortgageComponent.createMortgage")*/}
				{t('budget.pushBudget')}
			</Button>
            {userOperations && (
				<Modal
					className="ModalCommon ModalCommonSmallDelete"
					onCancel={handleCancelModal}
					open={showModalCreateMortgage}
					title={t('productsComponent.modalTitle')}
					closable={true}
					maskClosable={true}
					width={464}
					height={264}
					footer={[
						<Button 
							className="button-secundary-gibobs" 
							onClick={() => handleOk()} 
							style={{ display: env.api.url !== 'https://dev.api.gibobs.net' && 'none' }}>
							{t('productsComponent.createMortgage')}
						</Button>,
						<Button
							className="button-primari-gibobs"
							style={{ textAlign: 'center' }}
							onClick={() => handleCancelModal('home')}>
							{t('productsComponent.goHP')}
						</Button>
					]}>
					<Text style={{ fontSize: '12px', height: '152px', width: '600px' }}>
						{t('productsComponent.descModal')}
					</Text>
				</Modal>
			)}
          
            {chargeRedirect && 
				navigate('/distributor',{
					state: { referrer: 'create' } 
				})
			}
				
			
        </div>)
    );
};

export default CreateProduct;
